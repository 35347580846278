// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import 'material-icons/iconfont/material-icons.css';

@import './colors.scss';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// The default material font is Roboto
@include mat.core();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$oaman-enroll-theme: mat.define-light-theme(
  (
    color: (
      primary: $mat-primary,
      accent: $mat-accent,
      warn: $mat-warn
    )
  )
);

// Define app typography configuration
$oaman-typography: mat.define-typography-config($font-family: 'Roboto, "Helvetica Neue", sans-serif');

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.typography-hierarchy($oaman-typography);
@include mat.all-component-themes($oaman-enroll-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// red border on invalid form fields

.ng-invalid:not(form).ng-dirty {
  border: 2px solid $accent;
}

input,
textarea {
  font-family: inherit;
}

.mat-mdc-tooltip {
  font-size: 1.25rem;
}

// for input fields validation
.validation-msg {
  margin-top: 0.2rem;
  color: $accent;
}

// form styles
formly-form {
  display: block;
}

formly-validation-message {
  color: $error;
}

// apply the error color on the first span inside the label element
// which must be the asterix for the required fields
formly-field-checkbox label span:first-child.ng-star-inserted {
  color: $error;
}

app-root formly-field {
  input, select, textarea{
    border-color: #656565 !important;
    &.ng-invalid:not(form).ng-dirty {
      border-color: $accent !important;
    }
    &:disabled {
      background-color: #E2E2E2 !important;
    }
  }
  .mat-mdc-form-field-infix {
    input{
      border-color: $gray-light !important;
    }
  }
  .asterisk {
    color: $error;
  }
  //apply the error color within a checkbox
  .form-check{
    .form-check-label>span{
      color:$error;
    }
  }
  .zile-form-attachments {
    .topic {
      margin-right: 15px;
    }
    .slant {
      font-style: italic;
    }
    .underline {
      text-decoration: underline;
    }
    .noUnderline {
      text-decoration: none;
    }
    .spacer {
      margin-top: 3rem;
    }
  }
  .attachments-declarations-confirmation {
      font-weight: bold;
  }
  .form-date {
    .ng-invalid:not(form).ng-dirty {
      border: none;
    }
    formly-validation-message {
      font-size: 14px;
    }
    .mat-mdc-form-field.mat-focused .mat-form-field-label {
      color: inherit !important;
    }
    .mat-mdc-form-field.mat-form-field-invalid .mdc-line-ripple::before {
      border-bottom-color: $error !important;
    }

    .mat-mdc-form-field {
      margin-top: 1rem;
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
      .mdc-text-field--filled .mdc-line-ripple::after {
        border-bottom-color: rgba(0, 0, 0, 0.42);
      }
    }
  }
  .attorney-power-tip{
    font-size: 16px;
  }
}

.text-fit-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// login button global styles
.login-card oaman-button button {
  width: 276px;
  margin-bottom: 10px;
}

/*
  Hide arrows from input type=number.
  (see https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp)
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

oaman-form-multi-select-dropdown-type{

     .ng-invalid:not(form).ng-dirty {
    border: none !important;
     }

  .multiselect-dropdown {
    display: inline-block!important;
    //width: 100%!important;
    padding: 0 !important;
    font-size: 13px !important;
    //border-style: none !important;

    .dropdown-multiselect__caret {
      padding: 0 !important;
      width: 20px !important;
      height: 28px !important;
    }


    .dropdown-btn {
      display:  inline-block!important;
      min-height: 25px !important;
      padding: 0 !important;
      border-radius: 0 !important;
      border-style: solid !important;
      border-width: 1px !important;
      border-color: #4e4e4e !important;
      line-height: 2.5!important;
      span{
        padding-left: 4px !important;
      }

      .selected-item {
        max-width: none !important;
        border: none !important;
        color: #000000 !important;
        margin-left: 2px !important;
        margin-top: 2px !important;
        margin-bottom: 3px !important;
        background-color: #d3d3d3 !important;
        border-radius: 10px !important;
        line-height: 1.52857143!important;
        a{
          padding-left: 4px !important;
          font-weight: 600 !important;
          font-size: 120% !important;
          color: dimgray !important;
        }
      }
    }
  }

  .dropdown-list {
    margin-top: 4px !important;
    .item2{
      li > div:before{
        width: 12px !important;
        height: 12px !important;
        border: 1.6px solid #b2b2b2 !important;
      }
      li > div:after{
        top: 40% !important;
        left: 3px !important;
        width: 8px !important;
        height: 2px !important;
        margin-top: -2.8px !important;
      }
      li > input:checked + div:before{
        background: #767676 !important;
      }
    }
  }

}
