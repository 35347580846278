@use '@angular/material' as mat;

// Base colors are configured here
$primary: #C6394A;
$primary-light: #e8b0b7;
$accent: #ff0044;
$warn: #ed7f05;
$warn-lighten: #fdcc96;
$error: #C6394A;

$white: #ffffff;
$black: #000000;

$gray: #878787;
$gray-light: #F2F2F2;
$gray-medium: #CCCCCC;

// to be defined later
$grey: mat.define-palette(mat.$grey-palette);

// Define the theme for the material specification
$primary-palette: (
  Main: $primary,
  Light: $primary-light,
  Dark: $primary,
  contrast: (
    Main: $white,
    Light: $black,
    Dark: $white,
  )
);

$accent-palette: (
  Main: $accent,
  Light: $accent,
  Dark: $accent,
  contrast: (
    Main: $white,
    Light: $black,
    Dark: $white,
  )
);

$warn-palette: (
  Main: $warn,
  Light: $warn-lighten,
  Dark: $warn,
  contrast: (
    Main: $white,
    Light: $black,
    Dark: $white,
  )
);
$mat-primary: mat.define-palette($primary-palette, Main, Light, Dark);
$mat-accent: mat.define-palette($accent-palette, Main, Light, Dark);
$mat-warn: mat.define-palette($warn-palette, Main, Light, Dark);
